import styles from './question-mark-icon.module.css';

export const QuestionMark = ({
	isExtraInfoOpen,
}: {
	isExtraInfoOpen: boolean;
}) => {
	return (
		<svg
			height="60px"
			width="60px"
			version="1.1"
			id="Capa_1"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 422.686 422.686"
			fill="#000000"
			className={`${styles.icon} ${isExtraInfoOpen && styles.iconHovered}`}
		>
			<g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
			<g
				id="SVGRepo_tracerCarrier"
				strokeLinecap="round"
				strokeLinejoin="round"
			></g>
			<g id="SVGRepo_iconCarrier">
				<g>
					<g>
						<path
							// style="fill:#010002;"
							d="M211.343,422.686C94.812,422.686,0,327.882,0,211.343C0,94.812,94.812,0,211.343,0 s211.343,94.812,211.343,211.343C422.694,327.882,327.882,422.686,211.343,422.686z M211.343,16.257 c-107.565,0-195.086,87.52-195.086,195.086s87.52,195.086,195.086,195.086c107.574,0,195.086-87.52,195.086-195.086 S318.917,16.257,211.343,16.257z"
						></path>
					</g>
					<g>
						<g>
							<path
								// style="fill:#010002;"
								d="M192.85,252.88l-0.569-7.397c-1.707-15.371,3.414-32.149,17.647-49.227 c12.811-15.078,19.923-26.182,19.923-38.985c0-14.51-9.112-24.182-27.044-24.467c-10.242,0-21.622,3.414-28.735,8.819 l-6.828-17.924c9.388-6.828,25.605-11.38,40.692-11.38c32.726,0,47.52,20.2,47.52,41.83c0,19.346-10.811,33.295-24.483,49.511 c-12.51,14.794-17.07,27.312-16.216,41.83l0.284,7.397H192.85V252.88z M186.583,292.718c0-10.526,7.121-17.923,17.078-17.923 c9.966,0,16.785,7.397,16.785,17.924c0,9.957-6.544,17.639-17.07,17.639C193.419,310.349,186.583,302.667,186.583,292.718z"
							></path>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};
