import { useEffect, useState, useCallback, useRef, ReactElement } from 'react';
import background from './codesandbox.png';
import { bellaImgString } from './secretImg';
import { QuestionMark } from './question-mark-icon';
import styles from './App.module.css';
import { useHover } from './useHover';
import { useOutsideClick } from './useOutsideClick';

type FlagChar = {
	value: string | ReactElement;
	id: number;
};
type Flag = FlagChar[];

const uniqueId = () => {
	let id = 0;
	return () => id++;
};

const getId = uniqueId();

const isMobile = window.innerWidth <= 500;

const URL =
	'https://wgg522pwivhvi5gqsn675gth3q0otdja.lambda-url.us-east-1.on.aws/736368';

const useGetFlag = (): { isLoading: boolean; flagArr: Flag; error: string } => {
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState<string>('');
	const [flagArr, setFlagArr] = useState<Flag>([]);

	useEffect(() => {
		if (window.location.pathname === '/puzzle') {
			window.location.href =
				'https://codesandbox.io/p/sandbox/ramp-puzzle-fvn55f';
		}
	}, []);

	useEffect(() => {
		const getFlag = async () => {
			try {
				const resp = await fetch(URL, { cache: 'force-cache' });
				const flag = await resp.text();

				setFlagArr(
					flag.split('').map((char) => ({ value: char, id: getId() }))
				);
			} catch (e: unknown) {
				if (typeof e === 'string') {
					setError(e);
				} else if (e instanceof Error) {
					setError(e.message);
				} else {
					setError(JSON.stringify(e));
				}
			} finally {
				setIsLoading(false);
			}
		};
		getFlag();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		isLoading,
		flagArr,
		error,
	};
};

const renderFlag = (
	flag: Flag,
	handleFlagChange: (char: FlagChar) => void,
	timeout: number
) => {
	const timeouts: NodeJS.Timeout[] = [];
	flag.forEach((flagChar, idx) =>
		timeouts.push(
			setTimeout(() => handleFlagChange(flagChar), timeout + idx * timeout)
		)
	);

	return timeouts;
};

const convertStringToFlag = (str: string) =>
	str.split('').map((value) => ({ value, id: getId() }));

const likePuzzlesText = convertStringToFlag('o you like puzzles?');
const bellaCanHelpText = convertStringToFlag(
	`Bella will lead you to the real codesandbox ↓`
);

const MULTIPLIER = 1;

function App() {
	const [showImg, setShowImg] = useState(true);
	const [showText, setShowText] = useState(true);
	const timeouts = useRef<NodeJS.Timeout[]>([]);
	const { isLoading, flagArr, error } = useGetFlag();
	const [listItems, setListItems] = useState<Flag>([]);
	const [transitionStarted, setTransitionStarted] = useState(false);
	const [transitionFinished, setTransitionFinished] = useState(false);
	const [showBella, setShowBella] = useState(false);
	const [showExtraInfo, setShowExtraInfo] = useState(false);

	const outsideClickRef = useOutsideClick(() => {
		console.log('leave');
		setShowExtraInfo(false);
	});

	const { ref: mouseLeaveRef } = useHover({
		leaveCallback: () => setShowExtraInfo(false),
	});

	const handleFlagChange = useCallback((char: FlagChar) => {
		setListItems((state) => [...state, char]);
	}, []);

	const handleClickQuestion = () => {
		setShowExtraInfo(true);
	};

	useEffect(() => {
		if (flagArr.length && !isLoading && !error) {
			timeouts.current = renderFlag(
				flagArr,
				handleFlagChange,
				500 * MULTIPLIER
			);
			timeouts.current.push(
				setTimeout(() => {
					setShowImg(false);
					setShowText(false);
					setTransitionStarted(true);
				}, 7000 * MULTIPLIER)
			);
			timeouts.current.push(
				setTimeout(() => {
					setListItems([{ value: 's', id: getId() }]);
					setTransitionFinished(true);
				}, 9000 * MULTIPLIER)
			);
			timeouts.current.push(
				setTimeout(() => {
					setShowText(true);
					renderFlag(likePuzzlesText, handleFlagChange, 200 * MULTIPLIER);
				}, 10000 * MULTIPLIER)
			);
			timeouts.current.push(
				setTimeout(() => {
					setListItems((s) => [
						...s,
						{
							value: <div key={getId()} style={{ flexBasis: '100%' }} />,
							id: 0,
						},
					]);
					renderFlag(bellaCanHelpText, handleFlagChange, 75 * MULTIPLIER);
				}, 15000 * MULTIPLIER)
			);
			timeouts.current.push(
				setTimeout(() => {
					setShowBella(true);
				}, 17500 * MULTIPLIER)
			);
		}

		return () => timeouts.current.forEach((timeout) => clearTimeout(timeout));
	}, [flagArr, handleFlagChange, isLoading, error]);

	const errorMessage =
		error && `An error was encountered while fetching the flag: ${error}`;
	const loadingMessage = isLoading && 'Loading...';

	const Tag = transitionFinished ? 'span' : 'li';

	return (
		<div
			style={{
				height: '100vh',
				width: '100vw',
				backgroundColor: showImg ? '#0c0c0c' : '#ffffff',
				transition: 'background-color 1s',
			}}
		>
			<img
				alt=""
				src={background}
				style={{
					position: 'absolute',
					width: '100vw',
					transition: 'opacity 1s',
					opacity: showImg ? '1' : 0,
				}}
			/>
			<div
				style={{
					position: 'relative',
					top: isMobile ? '5%' : '17%',
					marginLeft: 'auto',
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				<div
					className={`${styles.mainTextContainer} ${
						transitionStarted && styles.mainTextContainerTransition
					}`}
					style={
						transitionFinished
							? { display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }
							: {}
					}
				>
					{errorMessage}
					{loadingMessage}
					{listItems.map(({ value, id }) =>
						typeof value === 'string' ? (
							<Tag
								style={{
									listStyleType: showImg ? 'disc' : 'none',
									display: transitionFinished ? 'inline' : 'list',
									opacity: showText || value === 's' ? '1' : '0',
									transition: 'opacity 1s',
									...(value === ' ' ? { width: '0.5ch' } : {}),
								}}
								key={id}
							>
								{value}
							</Tag>
						) : (
							value
						)
					)}
				</div>
			</div>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					height: '100vh',
					marginTop: 'auto',
				}}
			>
				<img
					style={{
						opacity: showBella ? '1' : '0',
						transition: 'opacity 1s',
						marginTop: '5%',
					}}
					width={isMobile ? '90%' : 'auto'}
					height={isMobile ? 'auto' : '70%'}
					src={bellaImgString}
					alt=""
				></img>
			</div>
			<div
				title="Solution"
				className={`${styles.infoContainer} ${
					showBella && styles.infoContainerVisible
				}`}
				onClick={(e) => {
					e.stopPropagation();
					handleClickQuestion();
				}}
			>
				<div title="" style={{ position: 'relative', cursor: 'default' }}>
					<div
						className={`${styles.infoModal} ${
							showExtraInfo && styles.infoModalShowing
						}`}
						ref={mouseLeaveRef}
					>
						<div ref={outsideClickRef} className={styles.infoText}>
							Inspecting the photo of Bella and looking at the last few
							characters of the base64 image leads to{' '}
							<code>
								codesan<strong>bd</strong>ox.io/puzzle
							</code>
							, which forwards to the real{' '}
							<a href="https://codesandbox.io/p/sandbox/ramp-puzzle-fvn55f?file=%2Fsrc%2FApp.tsx">
								codesandbox
							</a>
						</div>
					</div>
				</div>
				<QuestionMark isExtraInfoOpen={showExtraInfo} />
			</div>
		</div>
	);
}

export default App;
