import { useRef, useState, useEffect } from 'react';

export const useHover = ({
	enterCallback,
	leaveCallback,
}: {
	enterCallback?: () => void;
	leaveCallback?: () => void;
}) => {
	const ref = useRef<HTMLDivElement>(null);
	const [isHovered, setIsHovered] = useState(false);
	const enter = () => {
		if (enterCallback) {
			enterCallback();
		}
		setIsHovered(true);
	};
	const leave = () => {
		setIsHovered((currIsHovered) => {
			if (currIsHovered && leaveCallback) {
				leaveCallback();
			}
			return false;
		});
	};

	useEffect(() => {
		const el = ref.current;
		if (el) {
			el.addEventListener('mouseenter', enter);
			el.addEventListener('mouseleave', leave);

			return () => {
				el.removeEventListener('mouseenter', enter);
				el.removeEventListener('mouseleave', leave);
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return { ref, isHovered };
};
